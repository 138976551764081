import React, { useState, useEffect, useMemo } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate, useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { usePayment } from "../context/PaymentContext";
import PaymentForm from "./PaymentForm";
import { useSelector } from "react-redux";

const createPaymentIntent = async (amount, orderId) => {
	const amountInCents = Math.round(amount * 100);

	const response = await fetch(
		`${process.env.REACT_APP_APP_API_URL}stripe/${orderId}/payment-intent`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				action: "create",
				amount: amountInCents,
				currency: "gbp",
				orderId,
			}),
		}
	);
	const data = await response.json();
	return {
		clientSecret: data.data.clientSecret,
		paymentIntentId: data.data.paymentIntentId,
	};
};

const updatePaymentIntent = async (
	paymentIntentId,
	customerName,
	customerEmail,
	orderId
) => {
	const response = await fetch(
		`${process.env.REACT_APP_APP_API_URL}stripe/${orderId}/payment-intent`,
		{
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				action: "update",
				paymentIntentId,
				customerName,
				customerEmail,
			}),
		}
	);
	return response.json();
};

const PaymentDetails = ({ setFinalLoading, isFinalLoading }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { setPaymentSuccessful } = usePayment();

	const [clientSecret, setClientSecret] = useState("");
	const [paymentIntentId, setPaymentIntentId] = useState("");

	const cartItems = useSelector((state) => state.restaurantData.cartItems);
	const restaurantDetail = useSelector((state) => state.restaurantData.restaurantDetail);

	const restaurantId = location.state.orderData.restaurantId;
	const amount = location.state.orderData.totalAmount;
	const orderId = location.state.orderId;
	const tableNumber = location.state.tableNumber;
	const orderData = location.state.orderData;

  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
    stripeAccount: restaurantDetail?.paymentInfo?.destinationAccountId ?? null
  });

	useEffect(() => {
		const getClientSecret = async () => {
			if (orderId && amount) {
				try {
					const { clientSecret, paymentIntentId } = await createPaymentIntent(
						amount,
						orderId
					);
					setClientSecret(clientSecret);
					setPaymentIntentId(paymentIntentId);
					setFinalLoading(false);
				} catch (error) {
					console.error("Error creating PaymentIntent:", error);
					setFinalLoading(false);
				}
			}
		};

		getClientSecret();
	}, [amount, orderId, setFinalLoading]);

	const handleCustomerInfoChange = async (name, email) => {
		if (paymentIntentId) {
			try {
				await updatePaymentIntent(paymentIntentId, name, email, orderId);
			} catch (error) {
				console.error("Error updating PaymentIntent:", error);
			}
		}
	};

	const handleSuccess = (uniquePaymentIntentId) => {
		setPaymentSuccessful(true);

		navigate(`/order-success/${uniquePaymentIntentId}`, {
			state: {
				amount,
				orderId,
				restaurantId,
				tableNumber,
				orderData,
        cartItems
			},
		});
	};

	const options = useMemo(
		() => ({
			clientSecret,
			appearance: {
				theme: "stripe",
				variables: {
					borderRadius: "4px",
				},
			},
		}),
		[clientSecret]
	);

	return (
		<Box
			sx={{
				px: "16px",
			}}
		>
			{clientSecret && (
				<Elements stripe={stripePromise} options={options}>
					<PaymentForm
						amount={amount}
						onSuccess={handleSuccess}
						onCustomerInfoChange={handleCustomerInfoChange}
						paymentIntentId={paymentIntentId}
						clientSecret={clientSecret}
						orderId={orderId}
						setFinalLoading={setFinalLoading}
						isFinalLoading={isFinalLoading}
					/>
				</Elements>
			)}
		</Box>
	);
};

export default PaymentDetails;
